import React from 'react';
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import { Chip} from '@mui/material'
import CommonProduct, { headingLookup, toggleCtrl} from "../components/commonPage"
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import parse from 'html-react-parser'
var _ = require('underscore')

const order_by = {'relevance':"Relevance",'lastModifiedDate':"Last Modified",'name': "Name - Alphabetical"}

function wrapEmRegexStyle(text, searchText) {
  // Since algolia only return the <em> bracketed result for the first instance of search term in string, regex style highlighting is used below.
  return (searchText == '') ? (text || '') : ((text || '').replace(new RegExp(searchText, 'ig'), "<em>$&</em>")) 
}

class IndexPage extends CommonProduct {

  constructor(props) {
    super(props);
    this.homepage = true

    this.state.openDrawer = true;

    this.handleChange = this.handleChange.bind(this)
    
  }

  handleChange(e) {
    this.state.value  = e.target.value
  }

  setHighlightStrMap(hlMap){  //  highlighting string map from search.tsx
    this.setState({highlightStrMap:hlMap})
  }

  setProdObjMapping(prodObjMapping, queryID)
  {
    this.state.prodObjMapping = prodObjMapping
    this.state.queryID = queryID
  }

  toggleDrawer()
  {
    this.setState({openDrawer:!this.state.openDrawer})
  }

  render() {

    let {pageNum,focus,mv_lookups,products,page_info,highlightStrMap,userData,order} = this.state
    let toggleDrawer = this.toggleDrawer.bind(this)

    let headingKeys = Object.keys(headingLookup)
    let filterLookup = {}
    if (focus!=null && !!mv_lookups.products)
    {
      let choice = headingKeys[focus-1]
      let fieldLookup = this.filterTree[choice]
      if (fieldLookup)
      {
        for (let fieldName of Object.keys(fieldLookup) )
        {
          _.extendOwn(filterLookup, mv_lookups.products[fieldName])
        }
      }
    }
    
    let BaseComponent = this.BaseComponentRender.bind(this)
    let localPath=''
    if (this.isBrowser)
    {
      localPath=location.protocol+'//'+location.host
    }

    const nextPage = page_info && (page_info.page+1) || 1;
    let showingCount = 0
    if (!!page_info)
    {
      showingCount = (nextPage)*page_info.hitsPerPage
      if (showingCount>page_info.nbHits)
      {
        showingCount = page_info.nbHits
      }
    }

    const loadMoreButton = page_info && (page_info.nbPages>nextPage) || false

    return (
      <>        
      <Helmet>
        <meta property="og:title" content="ISTE Learning Technology Directory" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://index.edsurge.com/" />
        <meta property="og:image" content="https://index.edsurge.com/images/EdSurge_Bolt_Symbol_Green.png" />
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="630"/>
        <meta property="og:description" content="EdTech Index" />
      </Helmet>
    
      <BaseComponent controller={this} menu="home" title="Homepage | EdTech Index">

        <div className={this.state.openDrawer?"products open":"products"} key="products">
          <div id="sidepanel">
            <div className="bar"  onClick={toggleDrawer} />
            <div className="close" onClick={toggleDrawer} />
            <ol>
              <li>
                <h3>Find</h3>
                <p>Search reliable and up-to-date information on solutions that support teaching and learning.</p>
              </li>
              <li>
                <h3>Compare</h3>
                <p>Build your short list, and compare products using the criteria that matter most to you and your team.</p>
              </li>
              <li>
                <h3>Evaluate</h3>
                <p>Use our evaluation tool to answer questions about your educational context and narrow your list to the best-suited products.</p>
              </li>
              <li>
                <h3>Validate</h3>
                <p>Support your decision-making with validations from trusted organizations across critical quality indicators.</p>
              </li>
              
            </ol>
          </div>

        <div id="topInformation">
          <div id="results">
          <div className="showing">
              Showing {showingCount} of {page_info && page_info.nbHits || 0} results
            </div>
            <span className="hide_mobile">Order by</span>
                <DropdownButton className="dropdown" title={order_by[order]}>
                  {

                    Object.keys(order_by).map((fieldName, index)=>{
                      let heading = order_by[fieldName]
                      let updateResults = this.updateResults.bind(this)
                      let bound_values:any = {unique_id:'order',state:fieldName,controller:this,save_group:'results',onUpdate:updateResults}
                      let toggler:toggleCtrl = new toggleCtrl(bound_values)
                      let clickFunction = toggler.changeSelection.bind(toggler)
                      return <Dropdown.Item key={"order_"+fieldName} onClick={clickFunction} >{heading}</Dropdown.Item>
                    })
                  }
                </DropdownButton>
          </div>
        </div>

        {
            (products.length>0)  &&
            products.map( (product,index) => {
              if (!!product)
              {
                let product_key = 'product_'+product.productKey

                let updateMyList = this.updateMyList.bind(this)
                let my_list_state = this.state[product_key]
                let my_new_state = (!!my_list_state)?null:product.name
                let bound_values:any = {unique_id:product_key,state:my_new_state,controller:this,save_group:'my_list',onUpdate:updateMyList,
                    eventTrack: {type:'Add to my list', properties:{product_key:product.productKey,state:(my_new_state)?'add':'remove'}},
                  }
                let toggler:toggleCtrl = new toggleCtrl(bound_values)

                let clickFunction = toggler.changeSelection.bind(toggler)
                if (!userData || !userData.login) clickFunction = ()=>{this.setState({showLogin: true})}

                const highlights = highlightStrMap[product.productKey]

                let nameHighlightedRegexStyle= (highlights && highlights['name']?.value) || wrapEmRegexStyle(product.name, this.state.searchTerm)

                let descHighlightedRegexStyle= (highlights && highlights['publicDescription']?.value) || wrapEmRegexStyle(product.publicDescription, this.state.searchTerm)

                let organizationNameHighlightedRegexStyle= (highlights && highlights['organizationName']?.value) || wrapEmRegexStyle(product.organizationName, this.state.searchTerm)

                return <div className={(index==0)?"product first":"product"} key={'productwrap_'+product.productKey+`_indx${index}`}>
                    <div className="clickArea" onClick={()=>{this.productTrack(product, index+pageNum*page_info.hitsPerPage);navigate((!!product.ultid)?"/product/?ultid="+product.ultid:"/product/?recordId="+product.productKey)}}>
                      { product.featuredRank && <div className="featured">Featured Product</div> }
                      {
                        product.image_url &&
                        <div className="imgHolder"><img src={product.image_url} alt={product.name+" image"} /></div>
                      }
                      <strong onClick={()=>{this.productTrack(product, index+pageNum*page_info.hitsPerPage);navigate((!!product.ultid)?"/product/?ultid="+product.ultid:"/product/?recordId="+product.productKey)}}>{parse(nameHighlightedRegexStyle)}</strong>
                      <span className="byline">by {parse(organizationNameHighlightedRegexStyle)}</span>
                      {
                        (this.state.display_style=='list') &&
                        product.mv_items.map((filterKey,fIndx) => {
                          if (filterLookup[filterKey])
                          {
                            let highlightedChip = wrapEmRegexStyle( filterLookup[filterKey], this.state.searchTerm)
                            return <Chip key={product.productKey+'_'+filterKey} label={parse(highlightedChip)}/>
                          }
                        })
                      }
                      
                      <p className="desc">{parse(descHighlightedRegexStyle)} </p>
                    </div>
                    <div className="moreLink" onClick={()=>{this.productTrack(product, index+pageNum*page_info.hitsPerPage);navigate((!!product.ultid)?"/product/?ultid="+product.ultid:"/product/?recordId="+product.productKey)}}></div>

                    <div className="listButton">
                      {
                        my_list_state && <div className='compare' onClick={()=>{navigate("/my_list/")}}></div>
                      }
                      <button className={my_list_state?'selected':'deselected'} onClick={clickFunction}>{my_list_state?'':'Add to list'}</button>
                    </div>
                  </div>
              }
            })
          }
        </div>
        {
          loadMoreButton && <div className={this.state.openDrawer?"loadMore open":"loadMore"} onClick={()=>{this.setState({initSearch:false,pageNum:nextPage})}}>Load more</div>
        }
        <div id="linkBar" className={this.state.openDrawer && "open"}>
          <a id="edLogin" href={this.login_url}>Educator Login</a><a id="vendorLogin" href="https://ltd.iste.org/" target="_blank">Vendor Login</a>
          <a id="poweredByAWS" href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" /></a>
        </div>
      </BaseComponent>
      </>
    )
  }
}
export default IndexPage
